<template>
  <div class="container">
    <van-uploader :max-size="5242880" @oversize="onOversize" multiple :after-read="afterRead">
      <div class="upload-main">
        <van-icon name="photograph" />
      </div>
    </van-uploader>
  </div>
</template>

<script>
import { Uploader, Icon, Toast } from 'vant'
export default {
  name: 'UploadBtn',
  components: {
    [Uploader.name]: Uploader,
    [Icon.name]: Icon
  },
  methods: {
    afterRead (file) {
      console.log(file)
      const arr = Array.isArray(file) ? file : [file]
      console.log('arr', arr)
      arr.forEach(item => {
        this.$emit('selectImg', item)
      })
    },
    onOversize (file) {
      Toast('照片大小超出5MB')
    }
  }
}
</script>

<style scoped>
.container {

}
.upload-main {
  width: 100px;
  height: 100px;
  background-color: #f7f8fa;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dcdee0;
}
</style>
