<template>
  <div class="container-item">
    <div class="head">
      <div class="title">{{(index+1) +'、'}}{{data.name}}（{{$filters.fraction(data.points)}}）</div>
    </div>
    <div class="handle">
      <div>
        <stepper v-show="data.result === deductionType" :num="data.num" @minus="minus" @plus="plus" ></stepper>
      </div>
      <div>
        <c-button class="pass-btn" :color="data.result === passType? '#07c160' : ''" icon="success" title="通过" @click='pass' />
        <c-button :color="data.result === deductionType? '#ee0a24' : ''" icon="cross" title="扣分" @click='deduction' />
      </div>

    </div>
    <!-- 0:无需1:需要上传照片 -->
    <div class="photo" v-show="(data.pass_photo === 1 && data.result === passType) || (data.sub_photo === 1 && data.result === deductionType)">
      <upload-btn @selectImg="onSelectImg"></upload-btn>
      <template v-for="item,index in data.imgList" :key="index">
        <image-item v-if="!item.isDel" class="image-item-preview" :img="item" :index="index" @delImg="onDelImg(index)"></image-item>
      </template>
    </div>
  </div>
</template>

<script>
import CButton from '@/components/Button.vue'
import Stepper from '@/components/Stepper.vue'
import ImageItem from '@/components/ImageItem.vue'
import UploadBtn from '@/components/UploadBtn.vue'
import { Toast } from 'vant'

export default {
  name: 'Item',
  components: {
    CButton,
    Stepper,
    // ImageWrap,
    ImageItem,
    UploadBtn
  },
  props: {
    topicPoints: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      passType: 1, // 1通过
      deductionType: 2 // 2扣分
    }
  },
  methods: {
    handle (result, num, lastPoints) {
      this.$emit('onResult', { result, num, sub_points: num * this.data.points }, this.index, lastPoints)
    },
    pass () {
      // 通过
      if (this.result !== this.passType) {
        this.handle(this.passType, 0, this.topicPoints + this.data.sub_points)
      }
    },
    deduction () {
      // 扣分
      console.log('this.data', this.data)
      if (this.data.result !== this.deductionType) {
        console.log('jhjjjjjjjjjjjj')
        if (this.topicPoints >= this.data.points) {
          this.handle(this.deductionType, 1, this.topicPoints - this.data.points)
        } else {
          Toast('分数不够')
        }
      }
    },

    minus () {
      // 减
      if (this.data.num > 1 && this.data.result === this.deductionType) {
        this.handle(this.deductionType, this.data.num - 1, this.topicPoints + this.data.points)
      }
    },
    plus () {
      // 加
      if (this.data.result === this.deductionType) {
        if (this.topicPoints >= this.data.points) {
          this.handle(this.deductionType, this.data.num + 1, this.topicPoints - this.data.points)
        } else {
          Toast('分数不够')
        }
      }
    },
    onAddImg (url, imgIndex) {
      console.log(url, imgIndex)
      this.$emit('addImg', url, this.index, imgIndex)
    },
    onSelectImg (file) {
      console.log('onSelectImg', file)

      this.$emit('addImg', file, this.index)
    },
    onDelImg (imgIndex) {
      console.log('index', imgIndex)
      this.$emit('delImg', this.index, imgIndex)
    }
  }
}
</script>

<style scoped>
.container-item {
  background-color: #ffffff;
  /* margin: 20px; */
  padding: 20px;

}

.title {
  color: #393939;
  margin: 0 0 30px 0;
}

.handle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 0 30px 0; */
}
.pass-btn {
  margin: 0 10px 0 0;
}
.photo {
  display: flex;
  margin: 30px 0 0 0;
}
.image-item-preview {
  margin: 0 0 0 30px;
}
</style>
