<template>
  <div class="container">
    <div class="head">
      <div class="title">
        {{data.remark}}
      </div>
    </div>
    <div class="handle">
      <div class="sub-points">
        扣分：{{ $filters.fraction(data.sub_points) }}
      </div>
      <div>
        <c-button class="pass-btn"  icon="edit" title="编辑" @click='onEdit' />
        <c-button  icon="delete-o" title="删除" @click='onDel' />
      </div>
    </div>
    <div class="photo">
      <upload-btn @selectImg="onSelectImg"></upload-btn>
      <template v-for="item,index in data.imgList" :key="index">
        <image-item v-if="!item.isDel" class="image-item-preview" :img="item" :index="index" @delImg="onDelImg(index)"></image-item>
      </template>
    </div>
  </div>
</template>

<script>
import CButton from '@/components/Button.vue'
import ImageItem from '@/components/ImageItem.vue'
import UploadBtn from '@/components/UploadBtn.vue'
import { Field, NumberKeyboard } from 'vant'

export default {
  name: 'Custom',
  components: {
    CButton,
    ImageItem,
    UploadBtn,
    [Field.name]: Field,
    [NumberKeyboard.name]: NumberKeyboard
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    onEdit () {
      this.$emit('edit', this.data, this.index)
    },
    onDel () {
      this.$emit('del', this.data.id, this.index)
      // console.log(this.topicPoints)
    },
    onSelectImg (file) {
      console.log('onSelectImg', file)

      this.$emit('addImg', file, this.index)
    },
    onDelImg (imgIndex) {
      console.log('index', imgIndex)
      this.$emit('delImg', this.index, imgIndex)
    }
  }
}
</script>

<style scoped>
.input {
  padding: 0;
  /* border: 1px rgb(235, 237, 240) solid; */
  border: 1px rgb(231, 4, 4) solid;
  border-radius: 5px;
  background-color: #f6f6f6;
}
.container {
  background-color: #ffffff;
}
.title {
  color: #393939;
  margin: 0 0 30px 0;
}

.handle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px 0;
}
.pass-btn {
  margin: 0 10px 0 0;
}
.sub-points {
  width: 160px;
}
.photo {
  display: flex;
}
.image-item-preview {
  margin: 0 0 0 30px;
}
</style>
