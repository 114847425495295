<template>
  <div class="container">
    <div class="img-main">
      <div class="img-preview">
        <van-image fit="cover" width="100%" height="100%" :src="img.url" />
      </div>
      <div v-if="(img.status && img.status === 'uploading')" class="upload-status">
        <div><van-loading size="24" vertical>{{img.message}}</van-loading></div>
      </div>
    </div>
    <div class="img-del" @click="$emit('delImg')">
      <div class="img-del-icon">
        <van-icon name="minus" />
      </div>
    </div>
  </div>
</template>

<script>
import { Image as VanImage, Icon, Loading } from 'vant'

export default {
  name: 'ImageItem',
  components: {
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Loading.name]: Loading
  },
  props: {
    img: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.container {
  position: relative;
  width: 100px;
  height: 100px;
}
.img-main {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}
.img-main > div {
  position: absolute;
}

.img-preview {
  width: 100%;
  height: 100%;
}
.upload-status {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.418);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.img-del {
  position: absolute;
  top: -10px;
  right: -10px;
}
.img-del-icon {
  padding: 3px;
  background-color: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items:center;
  border-radius: 50%;

}

</style>
