<template>
  <div class="container">
    <div class="serial">
      <div @click="onBackRoute">
        <van-icon name="arrow-left" />
      </div>
      <div>
        第{{ topic.serial }}题
      </div>
      <div>
        <van-popover v-model:show="showPopover" placement="bottom-end" :actions="actions" @select="onSelect">
          <template #reference>
            <span>更多</span>
          </template>
        </van-popover>
      </div>
    </div>
    <div class="topic-info item-wrap">
      <div class="content">
        <div>{{ $route.params.communityName }}</div>
        <div>{{ $route.params.property }}</div>
      </div>

      <div class="content rules">
        <div class="content-title">标准内容</div>
        <div>{{ topic.content }}</div>

      </div>
      <div class="content rules">
        <div class="content-title">评分细则</div>
        <div>{{ topic.rules }}</div>
      </div>

    </div>
    <template v-for="(item, index) in list" :key="item.id">
      <div class="item-wrap">
        <template v-if="item.type === 0">
          <item :data="item" :index="index" :topicPoints="topicPoints" @onResult="result" @addImg="onAddImg" @delImg="onDelImg"/>
        </template>
        <template v-else>
          <!-- 自定义项 -->
          <custom :data="item" :index="index" @edit="onCustomEdit" @del="onCustomDel" @addImg="onAddImg" @delImg="onDelImg"/>
        </template>
      </div>
    </template>
    <div class="add-custom-btn" @click="onCustomAdd">
      <van-icon name="plus" />添加自定义
    </div>

    <div class="bar">
      <div class="bar-main">
        <div class="score">
          <span>得分：{{ $filters.fraction(topicPoints) }}</span>
        </div>
        <div class="page">
          <div class="prev" @click="onPage(-1)" v-show="!isFrist">上一题</div>
          <span class="dividing-line" v-show="!isFrist"></span>
          <div class="next" @click="onPage(1)" v-show="!isLast">下一题</div>
          <!-- <div class="next" v-show="isLast" @click="onFinish">完 成</div> -->
        </div>
      </div>
    </div>

    <van-overlay :show="addCustomShow" @click="addCustomShow = false">
      <custom-form
       :topicPoints="topicPoints"
       :model="customMid"
       :index="customIndex"
       :assessId="parseInt($route.params.assessId)"
       :topicId="parseInt($route.params.topicId)"
       @cancel="onCustomCancel"
       @save="onCustomSave" />
    </van-overlay>

    <van-popup v-model:show="historyShow">
      <div class="history-container">
        <div class="history-assess" v-for="assess in historyData" :key="assess.id">
          <div class="history-assess-title">{{ `${assess.year}年-第${assess.quarterly}次考核` }}</div>
          <div>
            <div class="history-item" v-for="(item, index) in assess.item" :key="item.id">
              <div class="history-item-title">
                <div>{{ `${index+1}、${item.title}（${$filters.fraction(item.points)}分）` }}</div>
                <div>
                  <template v-if="item.result === 1">
                    通过
                  </template>
                  <template v-else>
                    x{{item.num}}次{{$filters.fraction(item.sub_points)}}扣分
                  </template>
                </div>
              </div>
              <div class="history-item-img">
                <div class="history-item-img-wrap" v-for="(img, index) in item.img" :key="img.id">
                  <van-image
                    width="100%"
                    height="100%"
                    :src="img.url"
                    @click="onImagePreview(item.img, index)"
                  />
                </div>

              </div>
            </div>

          </div>
        </div>

        <div class="history-button-container">
          <div v-if="historyButtonType" class="history-button-wrap" @click="onBeforeHistory">
            更早以前
          </div>
          <div v-else class="history-button-wrap">
            没有数据了
          </div>
        </div>

      </div>
    </van-popup>
  </div>
</template>

<script>
import Item from '@/views/item/Index.vue'
import Custom from '@/views/custom/Index.vue'
import CustomForm from './components/CustomForm.vue'
import { getTopic, saveTopic, delCustom, finish, history } from '@/api/topic.js'

import { Overlay, Field, Toast, Icon, Dialog, Popover, Popup, Image as VanImage, ImagePreview } from 'vant'

import { qiniuToken } from '@/api/UploadImg'
import storage from 'store'
import * as qiniu from 'qiniu-js'

export default {
  name: 'Topic',
  components: {
    Item,
    Custom,
    CustomForm,
    [Overlay.name]: Overlay,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [Popover.name]: Popover,
    [Popup.name]: Popup,
    [VanImage.name]: VanImage
  },
  // watch: {

  // },
  data () {
    return {
      topicPoints: 0,
      list: [],
      topic: {},
      addCustomShow: false,
      isFrist: true,
      isLast: true,
      current: 0,
      customMid: null,
      customIndex: 0,

      actions: [{ text: '返回首页' }, { text: '返回模块' }, { text: '完成考核' }, { text: '历史考核' }],
      showPopover: false,

      historyPage: 1,
      historyShow: false,
      historyData: [],
      historyButtonType: true
    }
  },
  created () {
    this.loadData(this.$route.params.assessId, this.$route.params.topicId)
  },
  beforeRouteUpdate (to, from) {
    this.onSave()
    console.log('beforeRouteUpdate', to, from)
    this.loadData(to.params.assessId, to.params.topicId)

    // 重置历史考核
    this.historyPage = 1
    this.historyData = []
    this.historyButtonType = true
  },
  beforeRouteLeave (to, from) {
    if (!to.params.isSave) {
      this.onSave()
    }
    console.log('beforeRouteLeave', to, from)
    // 在导航离开渲染该组件的对应路由时调用
    // 与 `beforeRouteUpdate` 一样，它可以访问组件实例 `this`
  },
  methods: {
    onBackRoute () {
      this.$router.go(-1)
    },
    onSelect (action) {
      console.log(action)
      if (action.text === '返回首页') {
        this.$router.push({ name: 'Index' })
      } else if (action.text === '返回模块') {
        this.$router.push({ name: 'Module', params: { assessId: this.$route.params.assessId } })
      } else if (action.text === '完成考核') {
        this.onFinish()
      } else if (action.text === '历史考核') {
        //
        if (this.historyData.length === 0) {
          this.historyQuery()
        }
        this.historyShow = true
      }
    },
    onBeforeHistory () {
      this.historyQuery()
    },
    historyQuery () {
      const page = this.historyPage++
      history({ assessId: this.$route.params.assessId, topicId: this.$route.params.topicId, page }).then(res => {
        console.log(res)
        this.historyButtonType = res.data.length > 0
        this.historyData.push(...res.data)
      })
    },

    // 系统默认扣分项
    result (data, index, lastScore) {
      console.log(data, index, lastScore)
      if (this.list[index].status !== 2) {
        this.list[index].result = data.result
        this.list[index].num = data.num
        this.list[index].sub_points = data.sub_points
        this.topicPoints = lastScore
      }

      // this.list[index].result = type
      // this.list[index].result = type
    },
    // 自定义保存
    onCustomSave (topicPoints, data, index) {
      //
      if (index === 0) {
        // 新增自定义
        console.log('dddddd')
        if (this.list.some(item => item.type === 1)) {
          Toast('已添加自定义')
          return
        }
        this.list.push(data)
      } else {
        // 编辑自定义
        this.list[index].remark = data.remark
        this.list[index].sub_points = data.sub_points
        // this.customMid = {}
      }

      this.topicPoints = topicPoints
      this.addCustomShow = false
    },
    onCustomAdd () {
      this.customMid = null
      this.addCustomShow = true
    },
    onCustomCancel () {
      // this.customMid = null
      this.addCustomShow = false
    },
    // 自定义编辑
    onCustomEdit (data, index) {
      //
      // console.log(this.customMid)
      // this.customMid = {}
      this.addCustomShow = true
      this.customIndex = index
      this.customMid = data
      // console.log(this.customMid)
    },
    // 自定义删除
    onCustomDel (id, index) {
      Dialog.confirm({
        message: '确定删除自定义扣分？'
      }).then(() => {
        // on confirm
        console.log('del', id, index)
        delCustom({ id, assessId: this.$route.params.assessId, topicId: this.topic.id }).then(res => {
          this.topicPoints += this.list[index].sub_points
          this.list.splice(index, 1)
        })
      })
      // this.topicPoints = this.topicPoints + subPoints
      // this.list.splice(index, 1)
    },
    // 提交题目
    onSave () {
      console.log('save')
      const list = this.list.map(item => {
        // return (const { id, num, sub_points, type, result, status, remark } = item
        return {
          id: item.id,
          num: item.num,
          sub_points: item.sub_points,
          type: item.type,
          result: item.result,
          remark: item.remark,
          img: item.imgList
        }
      })
      // console.log('list', list)
      return saveTopic({ list, assessId: this.$route.params.assessId, topicId: this.topic.id }).then(res => {
        console.log('ddddd', res)
      })
    },
    // 分页
    onPage (type) {
      const arr = this.topic.expertTopic
      const params = {
        assessId: this.$route.params.assessId,
        topicId: arr[this.current + type]
      }
      console.log('dddddd')
      this.$router.push({ name: 'Topic', params })
    },
    // 获取数据
    loadData (assessId, topicId) {
      const params = {
        assessId: assessId,
        topicId: topicId
      }
      getTopic(params).then(res => {
        console.log('ree', res)
        this.list = res.data.item

        let subPoints = 0
        res.data.item.forEach(item => {
          subPoints += item.sub_points
        })

        this.topicPoints = res.data.points - subPoints
        console.log(this.topicPoints)
        this.topic = res.data

        // 分页处理
        const arr = this.topic.expertTopic
        const last = arr.length - 1
        this.current = arr.indexOf(this.topic.id)
        this.isFrist = this.current === 0
        this.isLast = this.current === last
        // if (this.current === 0) {
        //   // 当前第一页
        //   this.isFrist = false
        // }
        // if (this.current === last) {
        //   // 最后一页
        //   this.isLast = false
        // }
      })
    },
    // 添加图片
    async onAddImg (file, index) {
      console.log(file)
      const obj = {
        url: file.content,
        status: 'uploading',
        message: '等待',
        isDel: false
      }
      const length = this.list[index].imgList.push(obj)
      console.log('len', length)

      const host = 'https://qiniu.0573vote.com/'
      const _this = this

      // 获取本地七牛云token
      const qiniuTokenString = storage.get('qiniu-token')
      let token = null
      if (qiniuTokenString !== undefined) {
        const qiniuTokenObj = JSON.parse(qiniuTokenString)
        token = qiniuTokenObj.expiresTime * 1000 < new Date().getTime() ? null : qiniuTokenObj.token
      }

      // 从服务器获取七牛云token
      if (token === null) {
        await qiniuToken().then(res => {
          storage.set('qiniu-token', JSON.stringify(res.data))
          token = res.data.token
        })
      }

      // 七牛云上传参数
      const observer = {
        next (res) {
          // _this.uploadList[length - 1].status = 'uploading'
          console.log('_this.list[length - 1]', _this.list[length - 1])
          _this.list[index].imgList[length - 1].message = `${parseInt(res.total.percent)}%`
        },
        error (err) {
          file.status = 'failed'
          console.log('error', err.code)
        },
        complete (res) {
          const url = host + res.hash
          console.log('length - 1', length - 1)
          _this.list[index].imgList[length - 1].status = 'done'
          _this.list[index].imgList[length - 1].url = url
          console.log('_this.list', _this.list)
        }
      }

      // 上传图片
      const observable = qiniu.upload(file.file, null, token)
      const subscription = observable.subscribe(observer)
      console.log('subscription', subscription)
    },
    // 删除图片
    onDelImg (index, imgIndex) {
      if ('id' in this.list[index].imgList[imgIndex]) {
        this.list[index].imgList[imgIndex].isDel = true
      } else {
        this.list[index].imgList.splice(imgIndex, 1)
      }
    },
    // 完成考核
    async onFinish () {
      await this.onSave()
      finish({ assessId: this.$route.params.assessId }).then(res => {
        console.log('onFinish', res)
        Dialog.confirm({
          title: '完成考核',
          message: '是否需要返回首页'
        })
          .then(() => {
            this.$router.push({ name: 'Index', params: { isSave: 1 } })
          })
      }).catch(errors => {
        Dialog.confirm({
          message: `${errors.response.data.message}，是否直接前往`
        })
          .then(() => {
            // on confirm
            const params = {
              assessId: this.$route.params.assessId,
              topicId: errors.response.data.data.topicId
            }
            this.$router.push({ name: 'Topic', params })
          })
      })
    },
    onImagePreview (img, index) {
      // console.log(img)
      console.log(img.map(item => item.url))
      ImagePreview({
        images: img.map(item => item.url),
        startPosition: index,
        closeable: true
      })
    }
  }
}
</script>

<style scoped>
.container {
  padding: 20px 20px 110px 20px;
}

.item-wrap {
  border-radius: 20px;
  overflow: hidden;
  margin: 0 0 20px 0;
}
.add-custom-btn {
  height: 50px;
  /* background-color: aliceblue; */
  border: #ddd 1px dotted;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ddd;
  font-size: 30px;
  padding: 4px;
}
.bar {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0px;
  padding: 0 20px  20px  20px;
  box-sizing: border-box;
}
.bar-main {
  height:70px;
}
.bar > div {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  border:rgba(0, 0, 0, 0.288) 1px solid;

}
.score {
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;
}
.page {
  display:flex;
  background-color: #1b1e20;
  align-items: center;
  border-radius: 20px;
}
.page > div {

  color: #fff;
  width: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px 30px; */
}
.dividing-line {
  background-color: rgba(255, 255, 255, 0.644);
  width: 1px;
  height: 22px;
}
.topic-info {
  background-color: #fff;
  padding: 20px;
  font-size: 26px;
}
.content {
  background-color: #eef3f9;
  border-radius: 20px;
  padding: 20px;
}
.rules {
  margin: 20px 0 0 0;
}
.content-title {
  font-weight: 600;
  margin: 0 0 10px 0;
}
.serial {
  font-weight: bold;
  text-align: center;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
}
.prev {

}
.next {

}
.history-container {
  /* background-color: ; */
  padding: 20px;
}
.history-assess {
  border-radius: 20px;
  background-color: #eef3f9;
  padding: 10px;
  margin: 0 0 20px 0;
}
.history-assess-title {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 20px 0;
}
.history-item {
  font-size: 18px;
  width: 500px;
}
.history-item-title {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0
}
.history-item-img-wrap {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 10px;
}

.history-button-container {
  display: flex;
  justify-content: center;
  margin: 10px 0 0 0;
}
.history-button-wrap {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px #eef3f9 solid;
}

</style>
