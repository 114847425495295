<template>
  <div class="container">
    <div class="wrap">
      <div class="minus" @click="minus">
        <van-icon name="minus" />
      </div>
      <div class="value">{{num}}</div>
      <div class="plus" @click="plus">
        <van-icon name="plus" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant'
export default {
  name: 'Stepper',
  components: {
    [Icon.name]: Icon
  },
  props: {
    num: {
      type: Number,
      required: true
    }
  },
  data () {
    return {

    }
  },
  methods: {
    plus () {
      this.$emit('plus')
    },
    minus () {
      this.$emit('minus')
    }
  }
}
</script>

<style scoped>
.container {
  display: inline-block;
}
.wrap {
  display: flex;
}

.wrap > div {
  background-color: #f2f3f5;
  height: 56px;
  /* text-align: center;
  line-height: 56px; */
  color: #323233;
  /* font-size: 24px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.minus, .plus {
  width: 56px;
  border-radius: 56px 0 0 56px
}
.plus {
  width: 56px;
  border-radius: 0 56px 56px 0
}
.value {
  width: 70px;
  margin: 0 4px;
}
</style>
