<template>
  <div class="container" :style="styleObject" >
    <div class="main">
      <van-icon :name="icon" />
      <span class="text">{{title}}</span>
    </div>
  </div>
</template>
<script>
import { Icon } from 'vant'
export default {
  components: {
    [Icon.name]: Icon
  },
  props: {
    title: {
      type: String,
      default: '按钮'
    },
    color: {
      type: String,
      default: '#ffffff'
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      defaultStyleObject: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#ebedf0',
        color: '#323233'
      }
    }
  },
  computed: {
    styleObject () {
      if (this.color === '' || this.color === '#ffffff') {
        return this.defaultStyleObject
      }
      return { backgroundColor: this.color }
    }
  }
}
</script>

<style scoped>
.container {
  display:inline-block;
  border-radius: 56px;
  width: 156px;
  color: #fff;

  /* font-size: 22px; */
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
}
.text {
  margin: 0 0 0 10px;
}
</style>
