import request from '@/utils/request'

const api = {
  getTopic: '/assess/topic/topic',
  saveTopic: '/assess/topic/saveTopic',
  delCustom: '/assess/topic/delCustom',
  saveCustom: '/assess/topic/saveCustom',
  finish: '/assess/Assess/finish',
  history: '/assess/Assess/history'
}
export function history (parameter) {
  return request({
    url: api.history,
    method: 'get',
    params: parameter
  })
}

export function getTopic (parameter) {
  return request({
    url: api.getTopic,
    method: 'get',
    params: parameter
  })
}

export function saveTopic (parameter) {
  return request({
    url: api.saveTopic,
    method: 'post',
    data: parameter
  })
}

export function delCustom (parameter) {
  return request({
    url: api.delCustom,
    method: 'delete',
    params: parameter
  })
}

export function saveCustom (parameter) {
  return request({
    url: api.saveCustom,
    method: 'post',
    data: parameter
  })
}

export function finish (parameter) {
  return request({
    url: api.finish,
    method: 'get',
    params: parameter
  })
}
