import request from '@/utils/request'

const api = {
  qiniuToken: '/assess/qiniu/token',
  uploadImgUrl: '/assess/item/imgUrl'
}
export function qiniuToken (parameter) {
  return request({
    url: api.qiniuToken,
    method: 'post',
    data: parameter
  })
}

export function uploadImgUrl (parameter) {
  return request({
    url: api.uploadImgUrl,
    method: 'post',
    data: parameter
  })
}
