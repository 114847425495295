<template>
  <div class="container" >
    <div class="form" @click.stop>
      <div class="input-wrap">
        <van-field label-class="label-text" class="input" v-model="remark" label="内容:" placeholder="请输入原因（选填）" />
      </div>
      <div class="input-wrap">
        <!-- <van-field label="分值:" v-model="subPoints" readonly clickable @touchstart.stop="show = true"  :formatter="(value) => value.match(/^\d*(\.?\d{0,1})/g)[0]"/> -->
        <!-- <van-number-keyboard
          v-model="subPoints"
          :show="show"
          theme="custom"
          extra-key="."
          close-button-text="完成"
          @blur="show = false"
        /> -->
        <van-field v-model="subPoints" label-class="label-text"  type="number" label="分值:" placeholder="只支持小数点后一位"/>
      </div>
      <div class="btn-group">
        <div>
          <c-button class="pass-btn" color="#07c160" icon="passed" title="保存" @click='save' />
        </div>
        <div>
          <c-button class="pass-btn" color="#1989fa" icon="close" title="取消" @click='cancel' />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, NumberKeyboard, Toast } from 'vant'
import CButton from '@/components/Button.vue'
import { saveCustom } from '@/api/topic'
export default {
  name: 'CustomForm',
  components: {
    [Field.name]: Field,
    [NumberKeyboard.name]: NumberKeyboard,
    CButton
  },
  data () {
    return {
      remark: '',
      subPoints: '',
      show: false
    }
  },
  props: {
    topicPoints: {
      type: Number,
      required: true
    },
    assessId: {
      type: Number,
      required: true
    },
    topicId: {
      type: Number,
      required: true
    },
    model: {
      type: Object,
      default: () => null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  created () {
    console.log('this.model', this.model)
  },
  watch: {
    model: {
      handler () {
        console.log('this.modelthis.modelthis.modelthis.model', this.model)
        // if (this.model) {
        this.remark = this.model ? this.model.remark : ''
        this.subPoints = this.model ? this.$filters.fraction(this.model.sub_points) : ''
        // }
      },
      immediate: true
    }
  },
  methods: {
    save () {
      // 将分值转换成数字类型
      const subPoints = Math.trunc(Number(this.subPoints) * 10)
      let topicPoints = this.topicPoints
      if (this.index !== 0) {
        // 编辑时将原来的扣分值加回去
        topicPoints = this.topicPoints + this.model.sub_points
      }

      // 判断是否超分
      if (subPoints > topicPoints) {
        Toast('分值不够')
        return
      }

      const id = (this.model && this.model.id) ? this.model.id : 0

      saveCustom({ assessId: this.assessId, topicId: this.topicId, subPoints: subPoints, remark: this.remark, id }).then(res => {
        console.log(res)
        this.$emit('save', topicPoints - subPoints, res.data, this.index)
      }).catch(errors => {
        Toast(errors.response.data.message)
      })
    },
    cancel () {
      // this.remark = ''
      // this.subPoints = ''
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>
.container {
  /* background-color: #f7f8fa; */
  display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.form {
  background-color: #f7f8fa;
  padding: 20px;
  border-radius: 20px;
}
.input-wrap {
  width: 600px;
}
.input {
  /* background-color: #fff; */
  border-radius: 20px;
  margin: 0 0 10px 0;
}
.btn-group {
  margin: 30px 0 0 0;
  display: flex;
  justify-content: flex-end;
}
.btn-group > div {
  margin: 0 0 0 20px;
}

</style>
<style>
.label-text {
  width: auto;
}
</style>
